<template>
  <div>
    <div class="hero mb-8">
      <v-img src="@/assets/images/recruiter/home_banner.png" max-height="600px">
      </v-img>
      <span class="py-5 hero-btns" v-if="$vuetify.breakpoint.lgAndUp">
        <v-btn
          class="text-none text-body-1 mx-5 font-weight-medium"
          color="primary"
          elevation="0"
          x-large
          to="/jobs/create"
        >
          Post a Job
        </v-btn>
      </span>
      <div
        class="py-5 d-flex justify-space-around"
        v-if="$vuetify.breakpoint.mdAndDown"
      >
        <v-btn
          class="text-none text-body-2 mx-5 font-weight-medium"
          color="primary"
          elevation="0"
          to="/jobs/create"
        >
          Post a Job
        </v-btn>
      </div>
    </div>
    <!-- search box -->
    <!-- <v-row v-if="$vuetify.breakpoint.mdAndUp" class="d-flex flex-row flex-wrap justify-center mx-16">
      <v-col cols="4">
        <v-text-field
          outlined
          v-model="searchJobs"
          :prepend-inner-icon="mdiMagnify"
          dense
          label="Search Jobs"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-row class="justify-end">
          <v-col cols="4">
            <v-select
              :items="jobTypeFilters"
              item-value="value"
              item-text="name"
              label="Job Type"
              v-model="jobFilterSelected"
              outlined
              dense
              chips
              clearable
              :menu-props="{
                maxHeight: '400',
                maxWidth: '300',
                bottom: true,
                offsetY: true,
              }"
              multiple
              placeholder="jobType"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  small
                  close
                  @click:close="removeSelection('JOB_TYPE', item)"
                >
                  <span>{{ item.text }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              :items="locationFilters"
              label="Location"
              v-model="locationSelected"
              outlined
              dense
              chips
              clearable
              :menu-props="{
                maxHeight: '400',
                maxWidth: '300',
                bottom: true,
                offsetY: true,
              }"
              multiple
              placeholder="Location"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  small
                  close
                  @click:close="removeSelection('LOCATION', item)"
                >
                  <span>{{ item.text }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              :items="skillFilters"
              label="Skill"
              v-model="skillSelected"
              outlined
              dense
              chips
              clearable
              :menu-props="{
                maxHeight: '400',
                maxWidth: '300',
                bottom: true,
                offsetY: true,
              }"
              multiple
              placeholder="SKill"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  small
                  close
                  @click:close="removeSelection('SKILL', item)"
                >
                  <span>{{ item.text }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
    <!-- search box end -->
    <div class="d-flex flex-row flex-wrap justify-center">
      <JobListingCard
        v-for="job in displayJobList"
        :jobProp="job"
        :key="job.url"
      />
    </div>
  </div>
</template>
<script>
import JobListingCard from '@/components/jobs/JobListingCard.vue'
import { mdiMagnify } from '@mdi/js'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: { JobListingCard, mdiMagnify },
  data() {
    return {
      skeletonJobList: Array.from({ length: 10 }).fill({
        name: 'Company 1',
        locations: ['City 1', 'Longer City 2'],
        description:
          'We are looking for PROFESSIONAL WEB DEVELOPERS (FULL-STACK). We are looking for PROFESSIONAL WEB DEVELOPERS (FULL-STACK)',
        roleName: 'Software Engineer - II',
        jobType: 'FULL_TIME',
        jobPref: ['ONSITE'],
      }),
      displayJobList: [],
      filteredJobList: [],
      mdiMagnify,
      searchJobs: '',
      jobFilterSelected: [],
      jobTypeFilters: [
        {
          name: 'Full Time',
          value: 'FULL_TIME',
        },
        {
          name: 'Internship',
          value: 'INTERNSHIP',
        },
        {
          name: 'Contract',
          value: 'CONTRACT',
        },
      ],
      locationFilters: ['Delhi', 'Bangalore', 'Mumbai', 'London'],
      locationSelected: [],
      skillFilters: [
        'HTML',
        'CSS',
        'JavaScript',
        'Vue.js (with Vuex)',
        'Vuetify',
        'Basic GRPC',
      ],
      skillSelected: [],
    }
  },
  methods: {
    ...mapActions('candidate', ['fetchJobList', 'fetchSavedJobs']),
    removeSelection(type, item) {
      if (type === 'JOB_TYPE') {
        this.jobTypeSelected = [
          ...this.jobTypeSelected.filter((v) => v !== item.value),
        ]
      } else if (type === 'LOCATION') {
        this.locationSelected = [
          ...this.locationSelected.filter((v) => v !== item.value),
        ]
      } else if (type === 'SKILL') {
        this.locationSelected = [
          ...this.locationSelected.filter((v) => v !== item.value),
        ]
      } else {
      }
    },
  },
  computed: {
    ...mapState('candidate', ['jobList', 'savedJobs']),
    ...mapGetters('candidate', ['jobTypes', 'jobPrefs']),
    ...mapState('user', ['user']),
  },
  created() {
    this.fetchJobList({}).then(() => {
      this.displayJobList = []
      this.jobList.forEach((job) => {
        this.displayJobList.push({
          id: job?.getId(),
          roleName: job?.getTitle(),
          url: job?.getUrl(),
          locations: job?.getMetaData()?.getJobMeta()?.getLocationList(),
          description: '',
          jobType:
            this.jobTypes[job?.getMetaData()?.getJobMeta()?.getJobType()],
          jobPref: job
            ?.getMetaData()
            ?.getJobMeta()
            ?.getPreferenceList()
            .map((v) => this.jobPrefs[v]),
          // skills: ["Skill 1", "@TODO"],
          name: job?.getMetaData()?.getJobMeta()?.getCompanyName(),
          imageUrl: job?.getMetaData()?.getJobMeta()?.getCompanyLogoUrl(),
        })
      })
      if (this.user) {
        this.fetchSavedJobs().then(() => {
          console.log(this.savedJobs)
        })
      }
    })
  },
}
</script>
<style scoped>
.hero {
  position: relative;
}
.hero-btns {
  position: absolute;
  left: 48%;
  bottom: 15%;
}
</style>
