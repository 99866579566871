<template>
  <div>
    <v-hover close-delay="100" open-delay="100">
      <v-card
        rounded="lg"
        width="350px"
        class="ma-4 pa-3"
        color="#F9F9F9"
        :to="'/community/jobs/' + jobProp.url"
      >
        <v-card-title>
          <v-avatar color="primary" size="56">
            <v-img v-if="jobProp.imageUrl" :src="jobProp.imageUrl"></v-img>
            <span v-else class="white--text text-h5">{{
              companyInitials
            }}</span>
          </v-avatar>
          <div class="d-flex flex-column ml-5">
            <div class="name text-truncate" style="max-width: 150px">
              {{ jobProp.name }}
            </div>
            <div class="text-truncate" style="max-width: 150px">
              <span>
                <v-icon color="black">{{ mdiMapMarker }}</v-icon>
              </span>
              <span class="text-body-2">
                {{ jobProp.locations.join(', ') }}
              </span>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon elevation="0" @click="saveCurrentJob">
            <v-icon>
              {{ saved ? mdiBookmark : mdiBookmarkOutline }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div
            class="text-body-1 font-weight-bold text-truncate"
            style="max-width: 300px"
          >
            {{ jobProp.roleName }}
          </div>
          <div class="desc">
            {{ jobProp.description }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-space-between">
          <div>
            <v-icon>
              {{ mdiBriefcase }}
            </v-icon>
            <span class="text-body-2">
              {{ jobTypePretty(jobProp?.jobType) }}
            </span>
          </div>
          <div>
            <v-icon>
              {{ mdiSeat }}
            </v-icon>
            <span class="text-body-2">{{ jobProp.jobPref.join(', ') }} </span>
          </div>
        </v-card-actions>
      </v-card>
    </v-hover>
  </div>
</template>
<script>
import {
  mdiFormatListNumbered,
  mdiDotsHorizontal,
  mdiCurrencyInr,
  mdiMapMarker,
  mdiBookmark,
  mdiBookmarkOutline,
  mdiBriefcase,
  mdiWallet,
  mdiClose,
  mdiSeat,
} from '@mdi/js'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  props: {
    jobProp: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    mdiMapMarker,
    mdiBookmark,
    mdiBookmarkOutline,
    mdiBriefcase,
    mdiSeat,
    mdiDotsHorizontal,
    mdiCurrencyInr,
    mdiWallet,
    mdiFormatListNumbered,
    mdiClose,
    dialog: false,
    applying: false,
    alreadyAppliedToJob: undefined,
    saved: false,
    showModal: false,
  }),
  methods: {
    ...mapActions('candidate', ['applyToJob', 'fetchResume', 'saveJob']),
    jobTypePretty(job) {
      if (job === 'FULL_TIME') return 'Full Time'
      if (job === 'INTERNSHIP') return 'Internship'
      if (job === 'CONTRACT') return 'Contract'
      else return 'NA'
    },
    saveCurrentJob() {
      if (!this.user) {
        this.$store.dispatch('notifs/addNotif', {
          text: 'Login before applying to job',
          type: 'error',
        })
        this.applying = false
        return
      }
      this.saveJob({ id: this.jobProp.id }).then(() => {
        this.$store.dispatch('notifs/addNotif', {
          text: 'Job saved!',
          type: 'success',
        })
      })
    },
    applyToJobListing() {
      if (!this.user) {
        this.$store.dispatch('notifs/addNotif', {
          text: 'Login before applying to job',
          type: 'error',
        })
        this.applying = false
        return
      }
      this.applying = true
      this.applyToJob({ id: this.jobProp.id })
        .then(() => {
          this.$store.dispatch('notifs/addNotif', {
            text: 'Successfully applied to job!',
            type: 'success',
          })
          this.applying = false
          this.alreadyAppliedToJob = true
        })
        .catch((err) => {
          this.$store.dispatch('notifs/addNotif', {
            text: 'applyToJob: Error applying to job! Please try again later!',
            type: 'error',
          })
          this.applying = false
        })
    },
    showJobModal() {
      this.showModal = true
    },
    closeJobModal() {
      this.showModal = false
    },
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('candidate', ['savedJobs']),
    ...mapGetters('candidate', ['jobTypes']),
    companyInitials() {
      var t = this.jobProp.name.substring(0, 1)
      return t
    },
  },
  created() {},
  watch: {
    savedJobs() {
      console.log(this.savedJobs)
      this.saved = this.savedJobs.includes(this.jobProp.id)
    },
  },
}
</script>
